/**
 * Common styles for application
 */

// Margin
.m-base {
  margin: $align-base !important;
}
.mx-base {
  margin-left: $align-base !important;
  margin-right: $align-base !important;
}
.my-base {
  margin-top: $align-base !important;
  margin-bottom: $align-base !important;
}
.mt-base {
  margin-top: $align-base !important;
}
.mb-base {
  margin-bottom: $align-base !important;
}
.ml-base {
  margin-left: $align-base !important;
}
.mr-base {
  margin-right: $align-base !important;
}
.m-half {
  margin: $align-half !important;
}
.mx-half {
  margin-left: $align-half !important;
  margin-right: $align-half !important;
}
.my-half {
  margin-top: $align-half !important;
  margin-bottom: $align-half !important;
}
.mt-half {
  margin-top: $align-half !important;
}
.mb-half {
  margin-bottom: $align-half !important;
}
.ml-half {
  margin-left: $align-half !important;
}
.mr-half {
  margin-right: $align-half !important;
}
.m-quarter {
  margin: $align-quarter !important;
}
.mt-quarter {
  margin-top: $align-quarter !important;
}
.mb-quarter {
  margin-bottom: $align-quarter !important;
}
.mr-quarter {
  margin-right: $align-quarter !important;
}
.ml-quarter {
  margin-left: $align-quarter !important;
}
.m-0 {
  margin: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}

// Padding
.p-base {
  padding: $align-base !important;
}
.px-base {
  padding-left: $align-base !important;
  padding-right: $align-base !important;
}
.py-base {
  padding-top: $align-base !important;
  padding-bottom: $align-base !important;
}
.pt-base {
  padding-top: $align-base !important;
}
.pb-base {
  padding-bottom: $align-base !important;
}
.pl-base {
  padding-left: $align-base !important;
}
.pr-base {
  padding-right: $align-base !important;
}
.p-half {
  padding: $align-half !important;
}
.px-half {
  padding-left: $align-half !important;
  padding-right: $align-half !important;
}
.py-half {
  padding-top: $align-half !important;
  padding-bottom: $align-half !important;
}
.pt-half {
  padding-top: $align-half !important;
}
.pb-half {
  padding-bottom: $align-half !important;
}
.pl-half {
  padding-left: $align-half !important;
}
.pr-half {
  padding-right: $align-half !important;
}
.p-quarter {
  padding: $align-quarter !important;
}
.px-quarter {
  padding-left: $align-quarter !important;
  padding-right: $align-quarter !important;
}
.py-quarter {
  padding-top: $align-quarter !important;
  padding-bottom: $align-quarter !important;
}
.pt-quarter {
  padding-top: $align-quarter !important;
}
.pb-quarter {
  padding-bottom: $align-quarter !important;
}
.pl-quarter {
  padding-left: $align-quarter !important;
}
.pr-quarter {
  padding-right: $align-quarter !important;
}
.p-0 {
  padding: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}

.inset-0 {
  inset: 0 !important;
}

// Spacing
.w-100 {
  width: 100% !important;
}
.w-0 {
  width: 0 !important;
}
.w-50 {
  width: 50% !important;
}
.w-120px {
  width: 120px !important;
}
.h-100 {
  height: 100% !important;
}
.h-0 {
  height: 0 !important;
}

// Scrollbar
* {
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #e2dfdfd8;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 10px;
}

// Text typography
.text-primary {
  color: $color-primary !important;
}
.text-error {
  color: $color-error !important;
}
.text-warning {
  color: $color-warning !important;
}
.text-pending {
  color: $color-pending !important;
}
.text-success {
  color: $color-success !important;
}
.text-link {
  color: $color-link !important;
}
.text-h1 {
  @include typo-h1;
}
.text-h2 {
  @include typo-h2;
}
.text-h3 {
  @include typo-h3;
}
.text-16 {
  @include typo-body-16;
}
.text-14 {
  @include typo-body-14;
}
.text-caption {
  @include typo-caption;
}
.text-normal {
  font-weight: $font-weight-regular !important;
}
.text-medium {
  font-weight: $font-weight-medium !important;
}
.text-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}
.text-bold {
  font-weight: $font-weight-bold !important;
}
.text-break {
  word-break: break-word;
}
.text-keep-all {
  word-break: keep-all;
}
.text-linethrough {
  text-decoration: line-through !important;
}
.text-small {
  font-size: small !important;
}
.text-size-medium {
  font-size: medium !important;
}
.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Display type
.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-table-column {
  display: table-column !important;
}

// Visibility
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.opacity-3 {
  opacity: 0.3 !important;
}

// Transition related
.transform-x-0 {
  transform: translateX(0) !important;
}
.transform-x-25 {
  transform: translateX(-25%) !important;
}
.antd-transition {
  transition: all 0.3s !important;
}

// Pointer envent
.pointer-event-auto {
  pointer-events: auto;
}

// Flexbox
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-1 {
  flex: 1 !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-space-around {
  justify-content: space-around !important;
}
.justify-content-start {
  justify-content: start !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}

// Alignment
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-end {
  text-align: end !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}

// position
.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

// Whitespace
.white-space-pre {
  white-space: pre;
}

.white-space-pre-line {
  white-space: pre-line;
}

// Button
.link-button {
  @include color-button;
  background: $color-link !important;
}

// Gap
.gap-half {
  gap: $align-half !important;
}

.gap-base {
  gap: $align-base !important;
}

.primary-base-button {
  @include color-button;
  background: #75ab6d !important;
  &:focus-visible {
    outline: 4px solid #75ab6d !important;
  }
}

.text-primary-base-button {
  border: 1px solid #75ab6d;
  color: #75ab6d !important;
  &:hover {
    opacity: 0.85;
    border: 1px solid #75ab6d !important;
  }
  &:focus-visible {
    outline: 4px solid #75ab6d !important;
  }
}

.neutral-button {
  @include color-button;
  background: #a7abc3 !important;
  &:focus-visible {
    outline: 4px solid #a7abc3 !important;
  }
}

// Icon
.primary-icon {
  @include clickable-icon;
  color: $color-primary;
}
.link-icon {
  @include clickable-icon;
  color: $color-link;
}

// Customize style for components
.app-spin {
  position: fixed;
  top: 30%;
  width: 100%;
}
.theme-switch {
  background-color: rgb(67, 69, 70);
  .ant-switch-handle::before {
    background-color: #f78212;
  }
  img {
    margin-top: 1px;
  }
}

thead[class*='ant-table-thead'] th {
  background-color: #f1f6f0 !important;
}

h3 {
  display: inline-block;
}

img {
  border-radius: 10px;
}

.ant-pagination-simple-pager {
  visibility: hidden;
  width: 0px;
}

.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
  scale: 1;
}

.ant-image-preview-switch-left {
  left: 100px;
}

.ant-image-preview-switch-right {
  right: 100px;
}

.read-only {
  pointer-events: none !important;
}

// Marker
.custom-marker {
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
}

.custom-marker-default-has-data {
  background: #ffe040;
  border: 2px solid #ffa340;
}

.custom-marker-default-no-data {
  background: #bcbfd6;
  border: 2px solid #82869e;
}

.custom-marker-active {
  background: #1990ff;
  border: 2px solid #005eff;
}

.custom-marker-highlight {
  background: red;
  border: 2px solid #e96969;
}

.custom-marker-plot {
  width: 8px;
  height: 8px;
  display: block;
  position: relative;
  border-radius: 50%;
  background: #ee4039;
  border: 2px solid #ac2826;
}

.marker-circle {
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  border-radius: 50%;
}

.marker-active-tooltip {
  z-index: 1000;
}

// Cursor
.clickable {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

// border
.border-none {
  border: none !important;
}

// color
.green-color {
  color: #00aa5b;
}

.red-color {
  color: red;
}

.grey-color {
  color: #8c8c8c;
}

// background color
.bg-green-color {
  background-color: #00aa5b;
}

.bg-red-color {
  background-color: red;
}

// shape
.square {
  height: 10px;
  width: 10px;
  display: inline-block;
}

.circle {
  border-radius: 50%;
  display: inline-block;
}

.navigation-tabs {
  & > .ant-tabs-nav {
    background-color: #061f18;
    .ant-tabs-ink-bar {
      background: #ffba00;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0px;
    }
    .ant-tabs-tab {
      min-width: 110px;
      .ant-tabs-tab-btn {
        color: #fff;
        text-align: center;
        width: 100%;
      }
      &.ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: #ffba00;
      }
    }
  }
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.container-form-item-mb-0 {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
