/**
 * Customize styles for AntD components
 */

// Result
.ant-result {
  &.app-result-page {
    height: 100vh;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
  }
}

// Checkbox
.ant-checkbox-inner {
  border-radius: 0;
}

.ant-input-number,
.ant-input-affix-wrapper > input.ant-input {
  box-shadow: none !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: $color-secondary;
}

// Pagination
.ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
}

// Menu
.ant-menu {
  transition: $transition-base;
}

.ant-menu-title-content {
  transition: none !important;
}

// Leaflet bug, temporary fix
.leaflet-default-icon-path {
  background-image: url(https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png);
}

// Drawer
.ant-tabs {
  &.content-scrollable {
    overflow: hidden;
    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}

// Custom Input
.custom-input.ant-input {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  outline: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;

  &:hover,
  &:focus-within,
  &:focus {
    box-shadow: none !important;
  }
  &.ant-input-status-error {
    border-top: unset;
    border-left: unset;
    border-right: unset;
  }
}

.ant-form.form-readonly {
  input,
  textarea {
    color: $color-text-primary !important;
  }
  .ant-select {
    .ant-select-selection-item-content {
      color: $color-text-primary !important;
    }
    .ant-select-selection-item {
      color: $color-text-primary !important;
    }
  }
  .ant-checkbox {
    color: $color-text-primary;
    .ant-checkbox-inner::after {
      border-color: $color-text-primary;
    }
    + span {
      color: $color-text-primary;
    }
  }
  .ant-radio-wrapper {
    color: $color-text-primary;
    + span {
      color: $color-text-primary;
    }
  }
}
